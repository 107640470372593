import { ActionReducerMapBuilder, PayloadAction } from '@reduxjs/toolkit';
import {
  ProductType,
  ProductReduxStateType,
  InternalInfoType
} from 'src/types/product';
import { updateInternalPriceThunk } from '../thunks';
import { toast } from 'react-toastify';

export default function updateInternalPriceThunkHandler(
  builder: ActionReducerMapBuilder<ProductReduxStateType>
) {
  builder
    .addCase(
      updateInternalPriceThunk.pending,
      (state: ProductReduxStateType) => {
        state.isSendingRequest = true;
      }
    )
    .addCase(
      updateInternalPriceThunk.fulfilled,
      (
        state: ProductReduxStateType,
        action: PayloadAction<InternalInfoType & { product_id: number }>
      ) => {
        toast.success('Cập nhật giá thành công');
        state.products = state.products.map((product: ProductType) =>
          product.product_id === action.payload.product_id
            ? {
                ...product,
                internalInfo: {
                  cost_price: action.payload.cost_price,
                  shopee_retail_price: action.payload.shopee_retail_price,
                  tiktok_retail_price: action.payload.tiktok_retail_price
                }
              }
            : product
        );
        state.isSendingRequest = false;
      }
    )
    .addCase(
      updateInternalPriceThunk.rejected,
      (state: ProductReduxStateType, action: PayloadAction<any>) => {
        toast.error(action.payload.message);
        state.isSendingRequest = false;
      }
    );
}
