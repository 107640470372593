import { ActionReducerMapBuilder, PayloadAction } from '@reduxjs/toolkit';
import {
  ProductReduxStateType,
  SaleReportResponseType
} from 'src/types/product';
import { getSaleReportThunk } from '../thunks';
import { toast } from 'react-toastify';

export default function getSaleReportThunkHandler(
  builder: ActionReducerMapBuilder<ProductReduxStateType>
) {
  builder
    .addCase(getSaleReportThunk.pending, (state: ProductReduxStateType) => {
      state.isLoadingTable = true;
    })
    .addCase(
      getSaleReportThunk.fulfilled,
      (state, action: PayloadAction<SaleReportResponseType>) => {
        state.saleReport = action.payload.report;
        state.page = action.payload.page;
        state.totalPages = action.payload.totalPages;
        state.pageSize = action.payload.pageSize;
        state.isLoadingTable = false;
      }
    )
    .addCase(
      getSaleReportThunk.rejected,
      (state, action: PayloadAction<any>) => {
        // state.products = [];
        // state.page = 1;
        // state.totalPages = 1;
        state.isLoadingTable = false;
        toast.error(action.payload.message);
      }
    );
}
