import { ActionReducerMapBuilder, PayloadAction } from '@reduxjs/toolkit';
import {
  ProductType,
  ProductReduxStateType,
  ProductUpdatePriceThunkType
} from 'src/types/product';
import { updatePriceThunk } from '../thunks';
import { toast } from 'react-toastify';

export default function updatePriceThunkHandler(
  builder: ActionReducerMapBuilder<ProductReduxStateType>
) {
  builder
    .addCase(updatePriceThunk.pending, (state: ProductReduxStateType) => {
      state.isSendingRequest = true;
    })
    .addCase(
      updatePriceThunk.fulfilled,
      (
        state: ProductReduxStateType,
        action: PayloadAction<ProductUpdatePriceThunkType>
      ) => {
        toast.success('Cập nhật giá thành công');
        state.products = state.products.map((product: ProductType) =>
          product.product_id === action.payload.product_id
            ? {
                ...product,
                retail_price: action.payload.retail_price
                // citywhey_price: action.payload.citywhey_price,
                // compare_price: action.payload.compare_price
              }
            : product
        );
        state.isSendingRequest = false;
      }
    )
    .addCase(
      updatePriceThunk.rejected,
      (state: ProductReduxStateType, action: PayloadAction<any>) => {
        toast.error(action.payload.message);
        state.isSendingRequest = false;
      }
    );
}
