import {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse
} from 'axios';
import axios from 'axios';
import { toast } from 'react-toastify';
import { ToolkitStore } from '@reduxjs/toolkit/dist/configureStore';
import useActions from './useActions';

let store: ToolkitStore;

export interface IResponse<T = undefined> {
  errorCode: number;
  message: string;
  data: T;
}

export const injectStore = (_store: ToolkitStore) => {
  store = _store;
};

const local: AxiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_URL_API}`
});

local.interceptors.request.use(
  function (config: AxiosRequestConfig): AxiosRequestConfig {
    if (config.headers) {
      config.headers['Content-Type'] = 'application/json';
      config.headers.token = store.getState().auth.token;
    }
    return config;
  },
  function (error: AxiosError): Promise<AxiosError> {
    return Promise.reject(error);
  }
);

local.interceptors.response.use(
  function (response: AxiosResponse): AxiosResponse {
    if (response.status === 200 || response.status === 201) {
      return response.data;
    }
    return response;
  },
  async (err: AxiosError): Promise<AxiosError> => {
    console.log('err', err);
    let errorMessage: string = '';
    const authErrMsgList = [
      'invalid token',
      'jwt must malformed',
      'jwt must be provided',
      'jwt expired',
      'token must provided'
    ];
    if (err) {
      if (authErrMsgList.includes((err.response?.data as any)?.message)) {
        errorMessage = 'Xác thực người dùng thất bại!';
        toast.error(errorMessage);
        store.dispatch(useActions.auth.logout());
      }
    }
    if (err?.response) {
      return Promise.reject(err?.response?.data);
    }
    return Promise.reject(err);
  }
);

const auth = axios.create({
  baseURL: 'https://id.thegioiwhey.synology.me',
  headers: {
    'type-auth': 'outsideApp'
  }
});

// auth.interceptors.response.use(
//   function (response: AxiosResponse): AxiosResponse {
//     if (response.status === 200 || response.status === 201) {
//     }
//     return response;
//   },
//   async (err: AxiosError): Promise<AxiosError> => {
//     console.log('err', err);
//     // let errorMessage: string = '';
//     // const authErrMsgList = [
//     //   'invalid token',
//     //   'jwt must malformed',
//     //   'jwt must be provided',
//     //   'jwt expired',
//     //   'token must provided'
//     // ];
//     // if (err) {
//     //   if (authErrMsgList.includes((err.response?.data as any).message)) {
//     //     errorMessage = 'Xác thực người dùng thất bại!';
//     //     // store.dispatch(useActions.auth.logout({ err: errorMessage }));
//     //   }
//     // }
//     return Promise.reject(err);
//   }
// );

export default { local, auth };
