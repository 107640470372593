import axios from 'axios';
import React, { useState } from 'react';
import { saveAs } from 'file-saver';
import DownloadIcon from '@mui/icons-material/Download';
import { LoadingButton } from '@mui/lab';
import { useAppSelector } from 'src/hooks';

function ExcelReport({ month }: { month: string }) {
  const [loading, setLoading] = useState(false);
  const token = useAppSelector((state) => state.auth.token);
  const exportToExcel = async () => {
    axios({
      url: `${process.env.REACT_APP_URL_API}/api/products/download-report${
        month ? '?month=' + month : ''
      }`,
      method: 'GET',
      headers: { token },
      responseType: 'arraybuffer'
    })
      .then((response) => {
        const blob = new Blob([response.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        });

        saveAs(blob, `Bao-cao-doanh-thu-ban-hang-${month}.xlsx`);
      })
      .catch((error) => {
        console.error('Error downloading Excel file:', error);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  return (
    <LoadingButton
      loading={loading}
      variant="outlined"
      color="secondary"
      startIcon={<DownloadIcon />}
      onClick={exportToExcel}
    >
      Tải xuống báo cáo
    </LoadingButton>
  );
}

export default ExcelReport;
