import React from 'react';
import {
  Modal,
  Card,
  CardHeader,
  Divider,
  CardContent,
  Typography,
  CardActions,
  Button,
  FormControl,
  FormGroup,
  Box,
  TextField
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { UseFormType } from 'src/types/form';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import Loading from 'src/components/Loading';
import { useAppDispatch, useAppSelector } from 'src/hooks';
import useActions from 'src/hooks/useActions';
import { RootState } from 'src/store';
import { ProductEditWeightStateType } from 'src/types/product';

const StyledCard = styled(Card)({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '95%',
  maxWidth: 300
});

const WeightSchema: yup.SchemaOf<ProductEditWeightStateType> = yup
  .object()
  .shape({
    weight_value: yup
      .string()
      .required('Vui lòng không để trống khối lượng!')
      .matches(/^[0-9]\d*(\.\d+)?$/, 'Vui lòng nhập khối lượng hợp lệ!')
  });

export default function EditWeightModal({
  isOpen,
  onClose,
  productId
}: {
  isOpen: boolean;
  onClose: () => void;
  productId: number;
}) {
  const dispatch = useAppDispatch();

  const product = useAppSelector(
    (state: RootState) => state.product.chosenProduct
  );

  const isLoading = useAppSelector(
    (state: RootState) => state.product.isLoadingDetails
  );

  const isSending = useAppSelector(
    (state: RootState) => state.product.isSendingRequest
  );

  React.useEffect(() => {
    if (product) {
      editWeightForm.setValue('weight_value', String(product?.weight_value));
    } else {
      editWeightForm.setValue('weight_value', '');
    }
  }, [product]);

  React.useEffect(() => {
    dispatch(useActions.product.getProductDetails(productId));
  }, [productId]);

  const editWeightForm: UseFormType = useForm<ProductEditWeightStateType>({
    mode: 'onTouched',
    resolver: yupResolver(WeightSchema)
  });

  const handleClose = () => {
    editWeightForm.clearErrors();
    onClose();
  };

  const handleSubmit = (e: React.SyntheticEvent): void => {
    e.preventDefault();
    editWeightForm.handleSubmit(async (data: ProductEditWeightStateType) => {
      dispatch(
        useActions.product.updateWeight({ product_id: productId, ...data })
      );
    })();
  };

  return (
    <Modal open={isOpen} onClose={handleClose}>
      <StyledCard>
        <CardHeader title="Chỉnh sửa khối lượng sản phẩm" />
        <Divider />
        {product && (
          <>
            <CardContent>
              <Typography variant="h6" fontWeight={600}>
                {product.product_name}
              </Typography>
              <form onSubmit={handleSubmit}>
                <FormGroup>
                  <FormControl
                    sx={{ mt: 2 }}
                    error={
                      !!editWeightForm.formState.errors?.weight_value?.message
                    }
                  >
                    <TextField
                      name="weight_value"
                      margin="normal"
                      disabled={isSending}
                      label="Khối lượng"
                      fullWidth
                      {...editWeightForm.register('weight_value')}
                      error={!!editWeightForm.formState.errors?.weight_value}
                      helperText={
                        editWeightForm.formState.errors?.weight_value
                          ? editWeightForm.formState.errors.weight_value.message
                          : null
                      }
                    />
                  </FormControl>
                </FormGroup>
              </form>
            </CardContent>
            <CardActions sx={{ justifyContent: 'end' }}>
              <Button
                variant="contained"
                disabled={isSending}
                onClick={handleSubmit}
              >
                {!isSending ? 'Lưu' : 'Đang xử lý'}
              </Button>
              <Button
                disabled={isSending}
                onClick={handleClose}
                variant="outlined"
                color="inherit"
              >
                Đóng
              </Button>
            </CardActions>
          </>
        )}
        {isLoading && (
          <Box sx={{ position: 'relative', height: 130, width: '100%' }}>
            <Loading />
          </Box>
        )}
      </StyledCard>
    </Modal>
  );
}
