const generateParamsObject = (object: any) => {
  let result = {};
  for (const key in object) {
    if (object[key]) {
      result = { ...result, [key]: String(object[key]) };
    }
  }
  return result;
};

export default {
  generateParamsObject
};
