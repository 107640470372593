import useFetch from 'src/hooks/useFetch';
import { UserAccountType } from 'src/types/user';

const postLogin = async (user: UserAccountType) => {
  try {
    const response = await useFetch.auth.post('/login', user);
    if (response?.status === 200 || response?.status === 201)
      return response.data;
  } catch (error) {
    return error?.response?.data;
  }
};

export default {
  postLogin
};
