import { Navigate } from 'react-router-dom';
import { useAppSelector } from 'src/hooks';
import { RootState } from 'src/store';

export const ProtectedRoute = ({
  children
}: {
  children: JSX.Element[] | JSX.Element;
}) => {
  const isLogin = useAppSelector((state: RootState) => state.auth.isLogin);
  if (isLogin) {
    return <>{children}</>;
  } else {
    return <Navigate to={{ pathname: '/login' }} />;
  }
};

export const CheckLogin = ({
  children
}: {
  children: JSX.Element[] | JSX.Element;
}) => {
  const isLogin = useAppSelector((state: RootState) => state.auth.isLogin);

  if (!isLogin) {
    return <>{children}</>;
  } else {
    return <Navigate to={{ pathname: '/' }} />;
  }
};
