import { useRef, useState } from 'react';
import {
  Box,
  Button,
  Divider,
  lighten,
  Link,
  Popover,
  Typography
} from '@mui/material';

import { styled } from '@mui/material/styles';
import ExpandMoreTwoToneIcon from '@mui/icons-material/ExpandMoreTwoTone';
import LockOpenTwoToneIcon from '@mui/icons-material/LockOpenTwoTone';
import { useAppDispatch, useAppSelector } from 'src/hooks';
import { RootState } from 'src/store';
import useActions from 'src/hooks/useActions';
import { useParams } from 'react-router';

const UserBoxButton = styled(Button)(
  ({ theme }) => `
        padding-left: ${theme.spacing(1)};
        padding-right: ${theme.spacing(1)};
`
);

const MenuUserBox = styled(Box)(
  ({ theme }) => `
        background: ${theme.colors.alpha.black[5]};
        padding: ${theme.spacing(2)};
        text-align: center;
`
);

const UserBoxText = styled(Box)(
  ({ theme }) => `
        text-align: left;
        padding-left: ${theme.spacing(1)};
`
);

const UserBoxLabel = styled(Typography)(
  ({ theme }) => `
        font-weight: ${theme.typography.fontWeightBold};
        color: ${theme.palette.secondary.main};
        display: block;
`
);

const UserBoxDescription = styled(Typography)(
  ({ theme }) => `
        color: ${lighten(theme.palette.secondary.main, 0.5)}
`
);

function HeaderUserbox() {
  const { userInfo } = useAppSelector((state: RootState) => state.auth);

  const dispatch = useAppDispatch();
  const { chatbot_id } = useParams();

  const ref = useRef<any>(null);

  const [isOpen, setOpen] = useState<boolean>(false);

  const handleOpen = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
  };

  const handleLogout = (): void => {
    dispatch(useActions.auth.logout());
    handleClose();
  };

  const isLogin = useAppSelector((state: RootState) => state.auth.isLogin);

  return (
    <>
      <UserBoxButton color="secondary" ref={ref} onClick={handleOpen}>
        <UserBoxText>
          <UserBoxLabel variant="body1">
            {userInfo?.last_name} {userInfo?.first_name}
          </UserBoxLabel>
          {userInfo?.role ? (
            <UserBoxDescription variant="body2">
              {userInfo?.role}
            </UserBoxDescription>
          ) : null}
        </UserBoxText>
        <ExpandMoreTwoToneIcon sx={{ ml: 1 }} />
      </UserBoxButton>
      <Popover
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        <MenuUserBox sx={{ minWidth: 210 }} display="flex">
          <UserBoxText>
            <UserBoxLabel variant="body1">
              {userInfo?.last_name.trim()} {userInfo?.first_name.trim()}
            </UserBoxLabel>
            {userInfo?.role ? (
              <UserBoxDescription variant="body2">
                {userInfo?.role}
              </UserBoxDescription>
            ) : null}
          </UserBoxText>
        </MenuUserBox>
        <Divider />
        <Box sx={{ m: 1 }}>
          {isLogin ? (
            <Button color="primary" fullWidth onClick={handleLogout}>
              <LockOpenTwoToneIcon sx={{ mr: 1 }} />
              Đăng xuất
            </Button>
          ) : (
            <Button color="primary" fullWidth>
              <Link href={`/login/${chatbot_id}`}>Đăng nhập</Link>
            </Button>
          )}
        </Box>
      </Popover>
    </>
  );
}

export default HeaderUserbox;
