import { ReactNode, useState } from 'react';
import { Tooltip, IconButton, Typography, Stack } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import noImg from 'src/assets/img/no_img.png';
import { useAppSelector } from 'src/hooks';
import { RootState } from 'src/store';
import { Link } from 'react-router-dom';
import numeral from 'numeral';
import TGWTable, { TableBodyRowType } from 'src/components/Table';
import { ProductType } from 'src/types/product';

export default function SaleReportTable() {
  const saleReport = useAppSelector(
    (state: RootState) => state.product.saleReport
  );

  const isLoadingTable = useAppSelector(
    (state: RootState) => state.product.isLoadingTable
  );

  const headings: ReactNode[] = [
    <Typography key={2} fontWeight={700} textAlign="center">
      ID
    </Typography>,
    <Typography key={3} fontWeight={700} textAlign="center">
      Tên sản phẩm
    </Typography>,
    <Typography key={3} fontWeight={700} textAlign="center">
      Loại sản phẩm
    </Typography>,
    <Typography key={4} fontWeight={700} textAlign="center">
      Giá vốn
    </Typography>,
    <Typography key={4} fontWeight={700} textAlign="center">
      Giá bán lẻ Tiktok
    </Typography>,
    <Typography key={4} fontWeight={700} textAlign="center">
      Giá bán lẻ Shopee
    </Typography>,
    <Typography key={5} fontWeight={700} textAlign="center">
      Số lượng bán Tiktok
    </Typography>,
    <Typography key={6} fontWeight={700} textAlign="center">
      Số lượng bán Shopee
    </Typography>,
    <Typography key={6} fontWeight={700} textAlign="center">
      Số lượng bán Cửa hàng
    </Typography>
  ];

  const rows: TableBodyRowType[] = saleReport?.map((report) => {
    const row: TableBodyRowType = { id: '', data: [] };
    row.id = report.id;

    row.data.push(<Typography>{report.id}</Typography>);
    row.data.push(<Typography textAlign="start">{report.name}</Typography>);
    row.data.push(<Typography textAlign="start">{report.category}</Typography>);
    row.data.push(
      <Typography textAlign="end">
        {numeral(report.cost_price || 0).format('0,0')}
      </Typography>
    );
    row.data.push(
      <Typography textAlign="end">
        {numeral(report.tiktok_retail_price || 0).format('0,0')}
      </Typography>
    );
    row.data.push(
      <Typography textAlign="end">
        {numeral(report.shopee_retail_price || 0).format('0,0')}
      </Typography>
    );
    row.data.push(
      <Typography textAlign="end">
        {numeral(report.volume_of_tiktok || 0).format('0,0')}
      </Typography>
    );
    row.data.push(
      <Typography textAlign="end">
        {numeral(report.volume_of_shopee || 0).format('0,0')}
      </Typography>
    );
    row.data.push(
      <Typography textAlign="end">
        {numeral(report.volume_of_tiktok || 0).format('0,0')}
      </Typography>
    );

    return row;
  });

  return (
    <>
      <TGWTable
        headings={headings}
        rows={rows}
        isLoading={isLoadingTable}
        height={'calc(100vh - 246px)'}
      />
    </>
  );
}
