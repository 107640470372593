import {
  Stack,
  Pagination,
  PaginationItem,
  FormControl,
  InputLabel,
  Select,
  MenuItem
} from '@mui/material';

export default function TGWPagination({
  page,
  pageSize,
  totalPages,
  handleChangePageNumber,
  handleChangePageSize
}: {
  page: number;
  pageSize: number;
  totalPages: number;
  handleChangePageSize: (value: number, newPage: number) => void;
  handleChangePageNumber: (page: number) => void;
}) {
  const handleChangeSize = (value: number) => {
    const location = (Number(page || '1') - 1) * Number(pageSize || '10') + 1;
    const newPage = Math.ceil(location / value);
    handleChangePageSize(value, newPage);
  };

  return (
    <Stack direction="row" justifyContent="center" py={2}>
      <Pagination
        showFirstButton
        showLastButton
        page={page}
        color="primary"
        variant="outlined"
        shape="rounded"
        count={totalPages}
        siblingCount={3}
        renderItem={(item) => {
          if (item.type === 'first') item.page = 1;
          if (item.type === 'previous') item.page = Math.abs(page - 1) || 1;
          if (item.type === 'next') item.page = page + 1;
          if (item.type === 'last') item.page = totalPages;

          return (
            <PaginationItem
              {...item}
              onClick={() => handleChangePageNumber(item.page as number)}
            />
          );
        }}
      />
      <FormControl>
        <InputLabel>Số dòng</InputLabel>
        <Select
          sx={{ height: 32 }}
          value={[pageSize]}
          label="Số dòng"
          onChange={(e) => {
            handleChangeSize(Number(e.target.value));
          }}
        >
          <MenuItem value={10}>10</MenuItem>
          <MenuItem value={20}>20</MenuItem>
          <MenuItem value={30}>30</MenuItem>
          <MenuItem value={40}>40</MenuItem>
        </Select>
      </FormControl>
    </Stack>
  );
}
