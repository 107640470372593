import React from 'react';
import {
  CardContent,
  Container,
  Grid,
  Stack,
  Collapse,
  Tooltip,
  IconButton,
  Paper,
  Button,
  Typography
} from '@mui/material';
// import ProductTable from './products-table';
import { useAppDispatch, useAppSelector } from 'src/hooks';
import useActions from 'src/hooks/useActions';
import { RootState } from 'src/store';
import { Link, useSearchParams } from 'react-router-dom';
import paramsService from 'src/services/params';
// import FilterAndSearch from './filter-and-search';
import RefreshIcon from '@mui/icons-material/Refresh';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import FilterAltOffOutlinedIcon from '@mui/icons-material/FilterAltOffOutlined';
import TGWPagination from 'src/components/Pagination';
import SaleReportTable from './sale-report-table';
import DatePicker from 'src/components/CustomizedDatePicker';
import TimePicker from './time-picker';
import ExcelReport from './excel-report';

export default function SaleReportPage() {
  const dispatch = useAppDispatch();

  const [searchParams, setSearchParams] = useSearchParams();

  const [queryState, setQueryState] = React.useState<any>({});

  const totalPages = useAppSelector(
    (state: RootState) => state.product.totalPages
  );

  const needToReload = useAppSelector(
    (state: RootState) => state.appUI.needToReload
  );

  const [isShowFilterAndSearch, setShowFilterAndSearch] =
    React.useState<boolean>(false);

  const getReport = () => {
    const params = {};
    for (let [key, value] of searchParams.entries()) {
      params[key] = value;
    }

    dispatch(useActions.product.getSaleReport(params));
    if (JSON.stringify(params) === JSON.stringify(queryState)) return;
    setQueryState(paramsService.generateParamsObject(params));
  };

  React.useEffect(() => {
    getReport();
  }, [searchParams]);

  React.useEffect(() => {
    if (needToReload) {
      getReport();
      dispatch(useActions.appUI.updateNeedToReload(false));
    }
  }, [needToReload]);

  const handleToggleFilter = (): void => {
    setShowFilterAndSearch(!isShowFilterAndSearch);
  };

  const handleChangePageNumber = (value: number) => {
    setSearchParams(
      paramsService.generateParamsObject({
        ...queryState,
        page: String(value)
      })
    );
  };

  const handleChangePageSize = (value: number, newPage: number) => {
    setSearchParams(
      paramsService.generateParamsObject({
        ...queryState,
        pageSize: String(value),
        page: String(newPage)
      })
    );
  };

  const handleChangeMonth = (value: string) => {
    setSearchParams(
      paramsService.generateParamsObject({
        ...queryState,
        month: value
      })
    );
  };

  return (
    <>
      <Container maxWidth="lg" sx={{ padding: '0 1rem' }}>
        <Typography variant="h3" mb={2}>
          Báo cáo Số lượng sản phẩm bán ra trong tháng theo kênh
        </Typography>
        <Stack direction={'row'} justifyContent={'space-between'} gap={1}>
          <TimePicker value={queryState.month} onChange={handleChangeMonth} />
          <ExcelReport month={queryState.month} />
          <Stack direction="row" justifyContent="end" flex={1}>
            <Tooltip title="Refresh">
              <IconButton
                onClick={() =>
                  dispatch(useActions.appUI.updateNeedToReload(true))
                }
              >
                <RefreshIcon />
              </IconButton>
            </Tooltip>
          </Stack>
        </Stack>
        <Stack direction="row" sx={{ height: 0 }}>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="stretch"
            sx={{ padding: 0, flex: 1 }}
          >
            <Grid item xs={12} width={0}>
              <CardContent
                sx={{
                  padding: '1rem 0 0'
                }}
              >
                <SaleReportTable />
                <TGWPagination
                  page={Number(queryState.page) || 1}
                  pageSize={Number(queryState.pageSize) || 10}
                  totalPages={totalPages || 1}
                  handleChangePageNumber={handleChangePageNumber}
                  handleChangePageSize={handleChangePageSize}
                />
              </CardContent>
            </Grid>
          </Grid>
          <Collapse in={isShowFilterAndSearch} orientation="horizontal">
            {/* <FilterAndSearch query={queryState} /> */}
          </Collapse>
        </Stack>
      </Container>
    </>
  );
}
