import ReactDOM from 'react-dom';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';
import 'nprogress/nprogress.css';
import App from 'src/App';
import { SidebarProvider } from 'src/contexts/SidebarContext';
import * as serviceWorker from 'src/serviceWorker';
import { Provider } from 'react-redux';
import store, { persistor } from './store';
import { PersistGate } from 'redux-persist/integration/react';
import { injectStore } from './hooks/useFetch';

injectStore(store);

ReactDOM.render(
  <HelmetProvider>
    <PersistGate loading={null} persistor={persistor}>
      <SidebarProvider>
        <BrowserRouter>
          <Provider store={store}>
            <App />
          </Provider>
        </BrowserRouter>
      </SidebarProvider>
    </PersistGate>
  </HelmetProvider>,
  document.getElementById('root')
);

serviceWorker.unregister();
