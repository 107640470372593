import { createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import API from 'src/API';
import {
  ProductEditType,
  ProductEditPriceStateType,
  ProductEditWeightStateType,
  ProductImageType,
  ProductQueryType,
  ProductType,
  ProductUpdateImagesThunkType,
  ProductUpdatePriceThunkType,
  ProductUpdateWeightThunkType,
  InternalInfoType,
  InternalDetailProductType,
  SaleReportQueryType,
  SaleReportResponseType
} from 'src/types/product';

export const getProductsThunk = createAsyncThunk(
  'product/getProductsThunk',
  async (queryInfo: ProductQueryType, { rejectWithValue }) => {
    try {
      const response = await API.product.getProducts(queryInfo);
      switch (response.errorCode) {
        case 0:
          return response.data;
        case -1:
          return rejectWithValue({
            message: 'Vui lòng kiểm tra lại đường dẫn'
          });

        default:
          return rejectWithValue({ message: 'Lỗi hệ thống' });
      }
    } catch (error) {
      return rejectWithValue({
        message: error?.message || 'Lỗi hệ thống!'
      });
    }
  }
);

export const updatePriceThunk = createAsyncThunk<
  ProductUpdatePriceThunkType,
  ProductEditType<ProductEditPriceStateType>
>(
  'product/updatePriceThunk',
  async ({ product_id, ...data }, { rejectWithValue }) => {
    try {
      const response = await API.product.updatePrice(product_id, data);
      switch (response.errorCode) {
        case 0:
          return {
            product_id,
            // citywhey_price: Number(data.citywhey_price),
            retail_price: Number(data.retail_price)
            // compare_price: Number(data.compare_price)
          };

        case -2:
        default:
        case -1:
          return rejectWithValue({
            message: 'Vui lòng kiểm tra lại thông tin'
          });
      }
    } catch (error) {
      return rejectWithValue({ message: error?.message || 'Lỗi hệ thống!' });
    }
  }
);

export const updateWeightThunk = createAsyncThunk<
  ProductUpdateWeightThunkType,
  ProductEditType<ProductEditWeightStateType>
>(
  'product/updateWeightThunk',
  async ({ product_id, ...data }, { rejectWithValue }) => {
    try {
      const response = await API.product.updateWeight(product_id, data);
      switch (response.errorCode) {
        case 0:
          return {
            product_id,
            weight_value: Number(data.weight_value)
          };
        case -2:
        case -1:
        default:
          rejectWithValue({ message: 'Vui lòng kiểm tra lại thông tin' });
          break;
      }
    } catch (error) {
      return rejectWithValue({ message: error?.message || 'Lỗi hệ thống!' });
    }
  }
);

export const updateImageThunk = createAsyncThunk<
  ProductUpdateImagesThunkType,
  ProductEditType<{
    imgList: ProductImageType[];
  }>
>(
  'product/updateImageThunk',
  async ({ imgList, product_id }, { rejectWithValue }) => {
    try {
      const updateList = imgList
        .filter((img: ProductImageType) => !img.id)
        .map((img: ProductImageType) => img.full_path.split(',')[1]);
      const response = await API.product.uploadImages(product_id, updateList);
      switch (response.errorCode) {
        case 0:
          return {
            product_id,
            images: imgList
          };
        case -2:
        case -1:
          return rejectWithValue({
            message: 'Vui lòng kiểm tra lại thông tin'
          });
        case 1:
          return rejectWithValue({
            message: 'Có lỗi trong quá trình upload ảnh lên Sapo'
          });
        default:
          break;
      }
      return;
    } catch (error) {
      return rejectWithValue({ message: error?.message || 'Lỗi hệ thống!' });
    }
  }
);

export const getProductDetailsThunk = createAsyncThunk<
  ProductType,
  ProductType['product_id'] | undefined
>('product/getProductDetailsThunk', async (product_id, { rejectWithValue }) => {
  try {
    if (product_id) {
      const response = await API.product.getProduct(product_id);
      if (response.errorCode === 0) return response.data;
      return rejectWithValue({ message: 'Có lỗi xảy ra' });
    } else {
      return undefined;
    }
  } catch (error) {
    return rejectWithValue({ message: error?.message || 'Lỗi hệ thống! ' });
  }
});

export const deleteImageThunk = createAsyncThunk<
  { productId: ProductType['product_id']; deleteImg: ProductImageType },
  { productId: ProductType['product_id']; deleteImg: ProductImageType }
>(
  'product/deleteImageThunk',
  async ({ productId, deleteImg }, { rejectWithValue }) => {
    try {
      if (deleteImg?.id) {
        const response = await API.product.deleteImage(productId, deleteImg.id);
        switch (response.errorCode) {
          case 0:
            return { productId, deleteImg };
          case -2:
          default:
            return rejectWithValue({
              message: 'Vui lòng kiểm tra lại thông tin'
            });
        }
      } else {
        return { productId, deleteImg };
      }
    } catch (error) {
      return rejectWithValue({ message: error?.message || 'Lỗi hệ thống! ' });
    }
  }
);

export const getInternalDetailThunk = createAsyncThunk<
  InternalDetailProductType,
  number
>('product/getInternalProductr', async (productId, { rejectWithValue }) => {
  try {
    if (productId) {
      const response = await API.product.getInternalProductDetail(productId);
      console.log('response', response);
      switch (response.errorCode) {
        case 0:
          return response.data;
        case 1:
          return rejectWithValue({
            message: 'Không tìm thấy sản phẩm'
          });
        case -1:
          return rejectWithValue({
            message: 'Vui lòng kiểm tra lại thông tin'
          });

        default:
          return rejectWithValue({ message: 'Lỗi hệ thống' });
      }
    } else {
      return undefined;
    }
  } catch (error) {
    return rejectWithValue({ message: 'Lỗi hệ thống' });
  }
});

export const updateInternalPriceThunk = createAsyncThunk<
  InternalInfoType & { product_id: number },
  {
    productId: number;
    data: Pick<InternalInfoType, 'shopee_retail_price' | 'tiktok_retail_price'>;
  }
>(
  'product/updateInternalPrice',
  async ({ productId, data }, { rejectWithValue }) => {
    try {
      const response = await API.product.updateInternalPrice(productId, data);

      switch (response.errorCode) {
        case 0:
          return response.data.updatedProduct;
        case 1:
          return rejectWithValue({
            message: 'Không tìm thấy sản phẩm (Do đây là có thể combo)'
          });
        case -1:
          return rejectWithValue({
            message: 'Vui lòng kiểm tra lại thông tin'
          });

        default:
          return rejectWithValue({ message: 'Lỗi hệ thống' });
      }
    } catch (error) {
      return rejectWithValue({ message: 'Lỗi hệ thống' });
    }
  }
);

export const getSaleReportThunk = createAsyncThunk<
  SaleReportResponseType,
  SaleReportQueryType
>('product/getSaleReportThunk', async (queryInfo, { rejectWithValue }) => {
  try {
    const response = await API.product.getSaleReport(queryInfo);
    switch (response.errorCode) {
      case 0:
        return response.data;
      case -1:
        return rejectWithValue({
          message: 'Vui lòng kiểm tra lại đường dẫn'
        });
      default:
        return rejectWithValue({ message: 'Lỗi hệ thống' });
    }
  } catch (error) {
    return rejectWithValue({
      message: 'Lỗi hệ thống!'
    });
  }
});
