import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ProductImageType, ProductReduxStateType } from 'src/types/product';
import deleteImageThunkHandler from './thunk-handlers/delete-image';
import getProductDetailsThunkHandler from './thunk-handlers/get-product-details';
import getProductsThunkHandler from './thunk-handlers/get-products';
import updateImageThunkHandler from './thunk-handlers/update-images';
import updatePriceThunkHandler from './thunk-handlers/update-price';
import updateWeightThunkHandler from './thunk-handlers/update-weight';
import {
  deleteImageThunk,
  getInternalDetailThunk,
  getProductDetailsThunk,
  getProductsThunk,
  getSaleReportThunk,
  updateImageThunk,
  updateInternalPriceThunk,
  updatePriceThunk,
  updateWeightThunk
} from './thunks';
import updateInternalPriceThunkHandler from './thunk-handlers/update-internal-price';
import getInternalProductDetailsThunkHandler from './thunk-handlers/get-internal-product-details';
import getSaleReportThunkHandler from './thunk-handlers/get-sale-report';

const initialState: ProductReduxStateType = {
  products: [],
  saleReport: [],
  page: 1,
  totalPages: 1,
  pageSize: 20,
  chosenProduct: undefined,
  isLoadingTable: false,
  isLoadingDetails: false,
  isSendingRequest: false,
  isOpenConfirmDeleteModal: false
};

const slice = createSlice({
  name: 'product',
  initialState,
  reducers: {
    setOpenConfirmDeleteModal(
      state: ProductReduxStateType,
      action: PayloadAction<ProductReduxStateType['isOpenConfirmDeleteModal']>
    ) {
      state.isOpenConfirmDeleteModal = action.payload;
    },
    addImgToImgList(
      state: ProductReduxStateType,
      action: PayloadAction<ProductImageType>
    ) {
      state.chosenProduct = {
        ...state.chosenProduct,
        images: [...state.chosenProduct.images, action.payload]
      };
    }
  },
  extraReducers(builder) {
    getProductsThunkHandler(builder);
    getProductDetailsThunkHandler(builder);
    updatePriceThunkHandler(builder);
    deleteImageThunkHandler(builder);
    updateImageThunkHandler(builder);
    updateWeightThunkHandler(builder);
    updateInternalPriceThunkHandler(builder);
    getInternalProductDetailsThunkHandler(builder);
    getSaleReportThunkHandler(builder);
  }
});

export default {
  ...slice,
  actions: {
    ...slice.actions,
    getProducts: getProductsThunk,
    updatePrice: updatePriceThunk,
    updateInternalPrice: updateInternalPriceThunk,
    updateWeight: updateWeightThunk,
    updateImage: updateImageThunk,
    getProductDetails: getProductDetailsThunk,
    getInternalProductDetails: getInternalDetailThunk,
    deleteImage: deleteImageThunk,
    getSaleReport: getSaleReportThunk
  }
};
