import authSlice from 'src/store/auth-slice';
import productSlice from 'src/store/product';
import appUISlice from 'src/store/app-ui-slice';
import tagSlice from 'src/store/tag-slice';

const useActions = {
  auth: authSlice.actions,
  product: productSlice.actions,
  tag: tagSlice.actions,
  appUI: appUISlice.actions
};

export default useActions;
