import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import API from 'src/API';
import {
  UserSuccessLoginData,
  UserAccountType,
  UserInfoType
} from 'src/types/user';

const loginThunk = createAsyncThunk(
  'auth-slice/login',
  async (user: UserAccountType, { rejectWithValue }): Promise<any> => {
    const data = await API.auth.postLogin(user);
    if (data?.data) {
      toast.success('Đăng nhập thành công!');
      return data as UserSuccessLoginData;
    }
    if (data?.status === 'fail') {
      toast.error('Vui lòng kiểm tra lại thông tin đăng nhập!');
      return rejectWithValue(data.message);
    }
    toast.error('Lỗi hệ thống, thử lại sau!');
    return rejectWithValue('Server Error!');
  }
);

const initialState = {
  isLogin: false as boolean,
  userInfo: {} as UserInfoType,
  token: '' as string
};

const authSlice = createSlice({
  name: 'authSlice',
  initialState,
  reducers: {
    logout(state) {
      state.isLogin = false;
      state.userInfo = {} as UserInfoType;
      state.token = '';
    }
  },
  extraReducers(builder) {
    builder
      .addCase(
        loginThunk.fulfilled,
        (state, action: PayloadAction<UserSuccessLoginData>) => {
          state.isLogin = true;
          state.userInfo = action.payload.data;
          state.token = action.payload.jwt;
        }
      )
      .addCase(loginThunk.rejected, (state) => {
        state.isLogin = false;
        state.userInfo = {} as UserInfoType;
        state.token = '';
      });
  }
});

export default {
  ...authSlice,
  actions: {
    ...authSlice.actions,
    login: loginThunk
  }
};
