import { ActionReducerMapBuilder, PayloadAction } from '@reduxjs/toolkit';
import {
  ProductReduxStateType,
  ProductType,
  ProductUpdateWeightThunkType
} from 'src/types/product';
import { updateWeightThunk } from '../thunks';
import { toast } from 'react-toastify';

const updateWeightThunkHandler = (
  builder: ActionReducerMapBuilder<ProductReduxStateType>
) => {
  builder
    .addCase(updateWeightThunk.pending, (state) => {
      state.isSendingRequest = true;
    })
    .addCase(
      updateWeightThunk.fulfilled,
      (state, action: PayloadAction<ProductUpdateWeightThunkType>) => {
        state.products = state.products.map((product: ProductType) =>
          product.product_id === action.payload.product_id
            ? {
                ...product,
                weight_value: action.payload.weight_value
              }
            : product
        );
        toast.success('Cập nhật cân nặng thành công');
        state.isSendingRequest = false;
      }
    )
    .addCase(
      updateWeightThunk.rejected,
      (state, action: PayloadAction<any>) => {
        state.isSendingRequest = false;
        toast.error(action.payload.message);
      }
    );
};

export default updateWeightThunkHandler;
