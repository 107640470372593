import { ActionReducerMapBuilder, PayloadAction } from '@reduxjs/toolkit';
import { ProductType, ProductReduxStateType } from 'src/types/product';
import { getProductDetailsThunk } from '../thunks';
import { toast } from 'react-toastify';

export default function getProductDetailsThunkHandler(
  builder: ActionReducerMapBuilder<ProductReduxStateType>
) {
  builder
    .addCase(getProductDetailsThunk.pending, (state: ProductReduxStateType) => {
      state.isLoadingDetails = true;
    })
    .addCase(
      getProductDetailsThunk.fulfilled,
      (
        state: ProductReduxStateType,
        action: PayloadAction<ProductType | undefined>
      ) => {
        state.chosenProduct = action.payload;
        state.isLoadingDetails = false;
      }
    )
    .addCase(
      getProductDetailsThunk.rejected,
      (state: ProductReduxStateType, action: PayloadAction<any>) => {
        state.isLoadingDetails = false;
        toast.error(action.payload.message);
      }
    );
}
