import { useContext } from 'react';

import { alpha, Box, List, styled, Button, ListItem } from '@mui/material';
import { NavLink, NavLink as RouterLink } from 'react-router-dom';
import { SidebarContext } from 'src/contexts/SidebarContext';
import { RouteObject } from 'react-router';
import LayersIcon from '@mui/icons-material/Layers';
import TagIcon from '@mui/icons-material/Tag';
import routes from 'src/router';

const MenuWrapper = styled(Box)(
  ({ theme }) => `
  .MuiList-root {
    padding: ${theme.spacing(1)};

    & > .MuiList-root {
      padding: 0 ${theme.spacing(0)} ${theme.spacing(1)};
    }
  }

    .MuiListSubheader-root {
      text-transform: uppercase;
      font-weight: bold;
      font-size: ${theme.typography.pxToRem(12)};
      color: ${theme.colors.alpha.trueWhite[50]};
      padding: ${theme.spacing(0, 2.5)};
      line-height: 1.4;
    }
`
);

const StyledButton = styled(Button)({
  color: 'white'
});

const activeTab = {
  textDecoration: 'none',
  color: 'red',
  backgroundColor: '#5066fcc7',
  width: '100%',
  borderRadius: '10px'
};

const nonActiveTab = {
  textDecoration: 'none',
  width: '100%'
};

function SidebarMenu() {
  const { closeSidebar } = useContext(SidebarContext);

  return (
    <>
      <MenuWrapper>
        <List component="div">
          <ListItem component="div">
            <NavLink
              to="/products"
              style={({ isActive }) => (isActive ? activeTab : nonActiveTab)}
            >
              <StyledButton
                fullWidth
                disableRipple
                onClick={closeSidebar}
                sx={{ justifyContent: 'start' }}
                startIcon={<LayersIcon />}
              >
                Sản phẩm
              </StyledButton>
            </NavLink>
          </ListItem>
          <ListItem component="div">
            <NavLink
              to="/bao-cao"
              style={({ isActive }) => (isActive ? activeTab : nonActiveTab)}
            >
              <StyledButton
                fullWidth
                disableRipple
                onClick={closeSidebar}
                sx={{ justifyContent: 'start' }}
                startIcon={<LayersIcon />}
              >
                Báo cáo
              </StyledButton>
            </NavLink>
          </ListItem>
          {/* <ListItem component="div">
            <NavLink
              to="/tags"
              style={({ isActive }) => (isActive ? activeTab : nonActiveTab)}
            >
              <StyledButton
                fullWidth
                disableRipple
                onClick={closeSidebar}
                sx={{ justifyContent: 'start' }}
                startIcon={<TagIcon />}
              >
                Tag
              </StyledButton>
            </NavLink>
          </ListItem> */}
        </List>
      </MenuWrapper>
    </>
  );
}

export default SidebarMenu;
