import React, { useEffect } from 'react';
import {
  Modal,
  Card,
  CardHeader,
  Divider,
  CardContent,
  Typography,
  Button,
  TextField,
  Stack,
  Box
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { UseFormType } from 'src/types/form';
import { yupResolver } from '@hookform/resolvers/yup';
import Loading from 'src/components/Loading';
import { useAppDispatch, useAppSelector } from 'src/hooks';
import useActions from 'src/hooks/useActions';
import { RootState } from 'src/store';
import { ProductEditPriceStateType } from 'src/types/product';

const StyledCard = styled(Card)({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  minWidth: 300
});

const productPriceSchema: yup.SchemaOf<ProductEditPriceStateType> = yup
  .object()
  .shape({
    retail_price: yup
      .string()
      .required('Giá trị không được để trống!')
      .matches(/^(\d*\.)?\d+$/, 'Giá trị không hợp lệ!')
    // citywhey_price: yup
    //   .string()
    //   .required('Giá trị không được để trống!')
    //   .matches(/^(\d*\.)?\d+$/, 'Giá trị không hợp lệ!'),
    // compare_price: yup
    //   .string()
    //   .required('Giá trị không được để trống!')
    //   .matches(/^(\d*\.)?\d+$/, 'Giá trị không hợp lệ!')
  });

export default function EditPriceModal({
  isOpen,
  onClose,
  productId
}: {
  isOpen: boolean;
  onClose: () => void;
  productId: number;
}) {
  const dispatch = useAppDispatch();

  const product = useAppSelector(
    (state: RootState) => state.product.chosenProduct
  );

  const isLoading = useAppSelector(
    (state: RootState) => state.product.isLoadingDetails
  );

  const isSending = useAppSelector(
    (state: RootState) => state.product.isSendingRequest
  );

  const editPriceForm: UseFormType = useForm<ProductEditPriceStateType>({
    mode: 'onTouched',
    resolver: yupResolver(productPriceSchema)
  });

  useEffect(() => {
    dispatch(useActions.product.getProductDetails(productId));
  }, [productId]);

  useEffect(() => {
    if (product) {
      editPriceForm.setValue('retail_price', String(product.retail_price));
      // editPriceForm.setValue('citywhey_price', String(product.citywhey_price));
      // editPriceForm.setValue('compare_price', String(product.compare_price));
    } else {
      editPriceForm.setValue('retail_price', '');
      // editPriceForm.setValue('citywhey_price', '');
      // editPriceForm.setValue('compare_price', '');
    }
  }, [product]);

  const handleClose = () => {
    editPriceForm.clearErrors();
    onClose();
  };

  const handleSubmit = (e: React.SyntheticEvent): void => {
    e.preventDefault();
    editPriceForm.handleSubmit(async (data: ProductEditPriceStateType) => {
      dispatch(
        useActions.product.updatePrice({ ...data, product_id: productId })
      );
    })();
  };

  return (
    <Modal open={isOpen} onClose={handleClose}>
      <StyledCard>
        <CardHeader title="Chỉnh sửa giá sản phẩm" />
        <Divider />
        {product && (
          <CardContent>
            <Typography>
              Giá này cập nhật trực tiếp lên hệ thống Sapogo
            </Typography>
            <Typography variant="h4" fontWeight={600}>
              {product.product_name}
            </Typography>
            <form onSubmit={handleSubmit}>
              <TextField
                disabled={isSending}
                margin="normal"
                fullWidth
                label="Giá bán lẻ"
                name="retail_price"
                {...editPriceForm.register('retail_price')}
                error={!!editPriceForm.formState.errors?.retail_price}
                helperText={
                  editPriceForm.formState.errors?.retail_price
                    ? editPriceForm.formState.errors.retail_price.message
                    : null
                }
              />
              {/* <TextField
                disabled={isSending}
                margin="normal"
                fullWidth
                label="Giá CityWhey"
                name="citywhey_price"
                {...editPriceForm.register('citywhey_price')}
                error={!!editPriceForm.formState.errors?.citywhey_price}
                helperText={
                  editPriceForm.formState.errors?.citywhey_price
                    ? editPriceForm.formState.errors.citywhey_price.message
                    : null
                }
              />
              <TextField
                disabled={isSending}
                margin="normal"
                fullWidth
                label="Giá thị trường"
                name="compare_price"
                {...editPriceForm.register('compare_price')}
                error={!!editPriceForm.formState.errors?.compare_price}
                helperText={
                  editPriceForm.formState.errors?.compare_price
                    ? editPriceForm.formState.errors.compare_price.message
                    : null
                }
              /> */}
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="flex-end"
                spacing={2}
                sx={{ mt: 2 }}
              >
                <Button type="submit" disabled={isSending} variant="contained">
                  {!isSending ? 'Lưu' : 'Đang xử lý'}
                </Button>
                <Button
                  disabled={isSending}
                  onClick={handleClose}
                  variant="outlined"
                  color="inherit"
                >
                  Đóng
                </Button>
              </Stack>
            </form>
          </CardContent>
        )}
        {isLoading && (
          <Box sx={{ position: 'relative', height: 130, width: '100%' }}>
            <Loading />
          </Box>
        )}
      </StyledCard>
    </Modal>
  );
}
