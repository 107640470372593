import { createSlice } from '@reduxjs/toolkit';
import useActions from 'src/hooks/useActions';

const initialState = {
  isLoggingin: false as boolean,
  needToReload: false as boolean
};

const slice = createSlice({
  name: 'app_ui',
  initialState,
  reducers: {
    updateNeedToReload(state, action) {
      state.needToReload = action.payload;
    }
  },
  extraReducers(builder) {
    builder
      .addCase(useActions.auth.login.pending, (state) => {
        state.isLoggingin = true;
      })
      .addCase(useActions.auth.login.fulfilled, (state) => {
        state.isLoggingin = false;
      })
      .addCase(useActions.auth.login.rejected, (state) => {
        state.isLoggingin = false;
      });
  }
});

export default slice;
