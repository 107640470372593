import { Navigate } from 'react-router-dom';
import { RouteObject } from 'react-router';
import SidebarLayout from 'src/layouts/SidebarLayout';
import { ProtectedRoute, CheckLogin } from 'src/check-auth/Protected';
import Login from 'src/pages/login';
import ProductsPage from 'src/pages/product';
import ProductDetails from 'src/pages/product-details';
import ComboPage from './pages/combo/page';
import SaleReport from './pages/sale-report';

const routes: RouteObject[] = [
  {
    path: '/',
    element: (
      <ProtectedRoute>
        <SidebarLayout />
      </ProtectedRoute>
    ),
    children: [
      {
        path: '',
        element: <Navigate to="products" replace />
      },
      {
        path: 'products',
        element: <ProductsPage />
      },
      {
        path: 'products/:product_id',
        element: <ProductDetails />
      },
      { path: 'tao-combo', element: <ComboPage /> },
      { path: 'bao-cao', element: <SaleReport /> }
    ]
  },
  {
    path: 'login',
    element: (
      <CheckLogin>
        <Login />
      </CheckLogin>
    )
  }
];

export default routes;
