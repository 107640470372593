import React from 'react';
import {
  Modal,
  Card,
  CardHeader,
  Divider,
  CardContent,
  Typography,
  CardActions,
  Button,
  Grid,
  Stack,
  Box,
  Tooltip,
  IconButton
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import CancelIcon from '@mui/icons-material/Cancel';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import { styled } from '@mui/material/styles';
import { ProductImageType } from 'src/types/product';
import Loading from 'src/components/Loading';
import { useAppDispatch, useAppSelector } from 'src/hooks';
import useActions from 'src/hooks/useActions';
import { RootState } from 'src/store';

const StyledCard = styled(Card)({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  minWidth: 442
});
const StyledAddNewButton = styled(Stack)({
  height: '100%',
  minHeight: 150,
  cursor: 'pointer',
  border: '1px dashed grey',
  borderRadius: '4px',
  transition: '0.2s',
  '&:hover': {
    backgroundColor: '#eaeaea'
  }
});

const getBase64Url = (file: any) => {
  return new Promise<any>((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
};

export default function EditImageModal({
  isOpen,
  onClose,
  productId
}: {
  isOpen: boolean;
  onClose: () => void;
  productId: number;
}) {
  const dispatch = useAppDispatch();

  const product = useAppSelector(
    (state: RootState) => state.product.chosenProduct
  );

  const isSending = useAppSelector(
    (state: RootState) => state.product.isSendingRequest
  );

  const isLoading = useAppSelector(
    (state: RootState) => state.product.isLoadingDetails
  );

  const openDeleteConfirmModal = useAppSelector(
    (state: RootState) => state.product.isOpenConfirmDeleteModal
  );

  const imgList = product?.images;

  const [deleteImg, setDeleteImg] = React.useState<ProductImageType>();

  React.useEffect(() => {
    dispatch(useActions.product.getProductDetails(productId));
  }, [productId]);

  const handleClose = () => {
    onClose();
  };

  const handleSubmit = async () => {
    dispatch(
      useActions.product.updateImage({ product_id: productId, imgList })
    );
  };

  const handleChangeAddNewImageInput = async (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    try {
      const base64Url = await getBase64Url(e.target.files[0]);
      dispatch(useActions.product.addImgToImgList({ full_path: base64Url }));
    } catch (error) {}
  };

  // <--delete image
  const handleOpenDeleteConfirmModal = (img: ProductImageType) => {
    setDeleteImg(img);
    dispatch(useActions.product.setOpenConfirmDeleteModal(true));
  };

  const handleCloseDeleteConfirmModal = () => {
    dispatch(useActions.product.setOpenConfirmDeleteModal(false));
  };

  const handleDeleteImg = async () => {
    dispatch(useActions.product.deleteImage({ deleteImg, productId }));
  };
  // delete image -->

  // console.log('render modal');

  return (
    <>
      <Modal open={isOpen} onClose={handleClose}>
        <StyledCard>
          <CardHeader title="Chỉnh sửa hình ảnh" />
          <Divider />
          {product && (
            <>
              <CardContent>
                <Typography variant="h5" mb={1}>
                  {product.product_name}
                </Typography>
                <Grid container spacing={2}>
                  {imgList &&
                    imgList.map((img: ProductImageType, index: number) => (
                      <Grid key={index} item xs={4}>
                        <Box position="relative">
                          <img
                            src={img.full_path}
                            alt=""
                            width="100%"
                            height="auto"
                          />
                          <Tooltip title="Xóa hình ảnh">
                            <IconButton
                              disabled={isSending}
                              onClick={() => {
                                handleOpenDeleteConfirmModal(img);
                              }}
                              sx={{
                                position: 'absolute',
                                top: '4px',
                                right: '4px',
                                '&:hover': {
                                  backgroundColor: 'black'
                                }
                              }}
                            >
                              <CancelIcon color="error" />
                            </IconButton>
                          </Tooltip>
                          {!img.id && (
                            <Typography
                              position="absolute"
                              sx={{ top: '8px', left: '8px' }}
                            >
                              <AutoAwesomeIcon color="warning" />
                            </Typography>
                          )}
                        </Box>
                      </Grid>
                    ))}
                  <Grid item xs={4}>
                    <label htmlFor="add-image-input">
                      <StyledAddNewButton
                        justifyContent="center"
                        alignItems="center"
                      >
                        <AddIcon />
                      </StyledAddNewButton>
                    </label>
                    <input
                      type="file"
                      disabled={isSending}
                      onChange={handleChangeAddNewImageInput}
                      id="add-image-input"
                      accept="image/*"
                      style={{ display: 'none' }}
                    />
                  </Grid>
                </Grid>
              </CardContent>

              <CardActions sx={{ justifyContent: 'end' }}>
                <Button
                  disabled={isSending}
                  variant="contained"
                  onClick={handleSubmit}
                >
                  {!isSending ? 'Lưu' : 'Đang xử lý'}
                </Button>
                <Button
                  disabled={isSending}
                  onClick={handleClose}
                  variant="outlined"
                  color="inherit"
                >
                  Đóng
                </Button>
              </CardActions>
            </>
          )}
          {isLoading && (
            <Box sx={{ position: 'relative', height: 130, width: '100%' }}>
              <Loading />
            </Box>
          )}
        </StyledCard>
      </Modal>
      <Modal
        open={openDeleteConfirmModal}
        onClose={handleCloseDeleteConfirmModal}
      >
        <StyledCard sx={{ minWidth: 350 }}>
          <CardContent>
            <Typography variant="h4">
              Bạn có chắc muốn xóa hình ảnh này?
            </Typography>
          </CardContent>
          <CardActions sx={{ justifyContent: 'center' }}>
            <Button
              disabled={isSending}
              variant="contained"
              color="error"
              onClick={handleDeleteImg}
            >
              {!isSending ? 'Xóa' : 'Đang xử lý'}
            </Button>
            <Button
              disabled={isSending}
              onClick={handleCloseDeleteConfirmModal}
              variant="outlined"
              color="inherit"
            >
              Đóng
            </Button>
          </CardActions>
        </StyledCard>
      </Modal>
    </>
  );
}
