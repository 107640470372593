import { ReactNode, useState } from 'react';
import { Tooltip, IconButton, Typography, Stack } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import noImg from 'src/assets/img/no_img.png';
import EditImageModal from './edit-image-modal';
import EditPriceModal from './edit-price-modal';
import EditWeightModal from './edit-weight-modal';
import { useAppSelector } from 'src/hooks';
import { RootState } from 'src/store';
import { Link } from 'react-router-dom';
import numeral from 'numeral';
import TGWTable, { TableBodyRowType } from 'src/components/Table';
import { ProductType } from 'src/types/product';
import EditInternalPriceModal from './edit-internal-price-modal';
// import { useSocket } from 'src/hooks/useSocket';

export default function ProductTable() {
  const products = useAppSelector((state: RootState) => state.product.products);

  const isLoadingTable = useAppSelector(
    (state: RootState) => state.product.isLoadingTable
  );

  const [isOpenEditImageModal, setEditImageModal] = useState(false);

  const [isOpenEditPriceModal, setEditPriceModal] = useState(false);

  const [isOpenEditInternalPriceModal, setEditInternalPriceModal] =
    useState(false);

  const [isOpenEditWeightModal, setEditWeightModal] = useState(false);

  const [selectedProductId, setSelectedProductId] = useState<
    number | undefined
  >();

  const [selectedInternalProductId, setSelectedInternalProductId] = useState<
    number | undefined
  >();

  // const socket = useSocket(process.env.REACT_APP_URL_LOCAL_API, {
  //   reconnectionAttempts: 5,
  //   reconnectionDelay: 1000,
  //   autoConnect: true
  // });
  // useEffect(() => {
  //   socket.connect();
  //   StartListener();
  // }, []);

  // const StartListener = () => {
  //   // socket.on('connection', () => {
  //   //   console.log('first');
  //   // });
  //   socket.emit('hihi', 12);
  // };
  // <--edit image
  const handleOpenEditImageModal = (productId: number) => {
    setEditImageModal(true);
    setSelectedProductId(productId);
  };

  const handleCloseEditImageModal = () => {
    setEditImageModal(false);
    setSelectedProductId(undefined);
  };
  // edit image-->

  // <--edit price
  const handleOpenEditPriceModal = (productId: number) => {
    setEditPriceModal(true);
    setSelectedProductId(productId);
  };

  const handleCloseEditPriceModal = () => {
    setSelectedProductId(undefined);
    setEditPriceModal(false);
  };

  // edit price-->

  // <--edit internal price
  const handleOpenEditInternalPriceModal = (productId: number) => {
    setEditInternalPriceModal(true);
    setSelectedInternalProductId(productId);
  };

  const handleCloseEditInternalPriceModal = () => {
    setSelectedInternalProductId(undefined);
    setEditInternalPriceModal(false);
  };

  // edit internal price-->

  // <--edit weight
  const handleOpenEditWeightModal = (productId: number) => {
    setEditWeightModal(true);
    setSelectedProductId(productId);
  };

  const handleCloseEditWeightModal = () => {
    setSelectedProductId(undefined);
    setEditWeightModal(false);
  };
  // edit weight-->

  const headings: ReactNode[] = [
    <Typography key={0} fontWeight={700} textAlign="center"></Typography>,
    <Typography key={1} fontWeight={700} textAlign="center">
      Ảnh
    </Typography>,
    <Typography key={2} fontWeight={700} textAlign="center">
      ID
    </Typography>,
    <Typography key={3} fontWeight={700} textAlign="center">
      Tên sản phẩm
    </Typography>,
    <Typography key={3} fontWeight={700} textAlign="center">
      Loại sản phẩm
    </Typography>,
    <Typography key={4} fontWeight={700} textAlign="center">
      Giá vốn
    </Typography>,
    <Typography key={4} fontWeight={700} textAlign="center">
      Giá bán lẻ
    </Typography>,
    <Typography key={5} fontWeight={700} textAlign="center">
      Giá Tiktok
    </Typography>,
    <Typography key={6} fontWeight={700} textAlign="center">
      Giá Shopee
    </Typography>,
    <Typography key={8} fontWeight={700} textAlign="center">
      Số lượng phiên bản
    </Typography>,
    <Typography key={9} fontWeight={700} textAlign="center">
      Khối lượng
    </Typography>,
    <Typography key={10} fontWeight={700} textAlign="center">
      Tồn kho
    </Typography>
  ];

  const rows: TableBodyRowType[] = products?.map((product: ProductType) => {
    const row: TableBodyRowType = { id: '', data: [] };
    row.id = product.product_id;
    row.data.push(
      <Tooltip title="Cập nhật mô tả sản phẩm">
        <Link to={`/products/${product.product_id}`}>
          <IconButton>
            <EditIcon />
          </IconButton>
        </Link>
      </Tooltip>
    );
    row.data.push(
      <Tooltip title="Cập nhật hình ảnh">
        <IconButton
          onClick={() => {
            handleOpenEditImageModal(product.product_id);
          }}
        >
          <img
            src={product.images[0]?.full_path || noImg}
            alt={product.product_name}
            width={48}
            height={48}
          />
        </IconButton>
      </Tooltip>
    );
    row.data.push(<Typography>{product.product_id}</Typography>);
    row.data.push(
      <Typography textAlign="start">{product.product_name}</Typography>
    );
    row.data.push(
      <Typography textAlign="start">{product.category}</Typography>
    );
    row.data.push(
      <Typography textAlign="start">
        {numeral(product.internalInfo?.cost_price || 0).format('0,0')}
      </Typography>
    );
    row.data.push(
      <Stack direction="row" justifyContent="end" alignItems="center">
        <Typography>
          {numeral(product.retail_price || 0).format('0,0')}
        </Typography>
        <Tooltip title="Cập nhật giá">
          <IconButton
            onClick={() => {
              handleOpenEditPriceModal(product.product_id);
            }}
          >
            <EditIcon />
          </IconButton>
        </Tooltip>
      </Stack>
    );
    // row.data.push(
    //   <Typography textAlign="end">
    //     {/* {numeral(product.citywhey_price || 0).format('0,0')} */}
    //     {numeral(product.internalInfo?.tiktok_retail_price || 0).format('0,0')}
    //   </Typography>
    // );

    row.data.push(
      <Stack direction="row" justifyContent="end" alignItems="center">
        <Typography>
          {numeral(product.internalInfo?.tiktok_retail_price || 0).format(
            '0,0'
          )}
        </Typography>
        <Tooltip title="Cập nhật giá">
          <IconButton
            onClick={() => {
              handleOpenEditInternalPriceModal(product.product_id);
            }}
          >
            <EditIcon />
          </IconButton>
        </Tooltip>
      </Stack>
    );
    row.data.push(
      <Typography textAlign="end">
        {numeral(product.internalInfo?.shopee_retail_price || 0).format('0,0')}
      </Typography>
    );
    row.data.push(
      <Typography textAlign="center">{product.variant_count}</Typography>
    );
    row.data.push(
      <Stack direction="row" justifyContent="end" alignItems="center">
        {product.weight_value}
        <Tooltip title="Cập nhật khối lượng">
          <IconButton
            onClick={() => {
              handleOpenEditWeightModal(product.product_id);
            }}
          >
            <EditIcon />
          </IconButton>
        </Tooltip>
      </Stack>
    );
    row.data.push(
      <Typography textAlign="center">{product.in_stock}</Typography>
    );
    return row;
  });

  return (
    <>
      <TGWTable
        headings={headings}
        rows={rows}
        isLoading={isLoadingTable}
        height={'calc(100vh - 246px)'}
      />
      <EditImageModal
        isOpen={isOpenEditImageModal}
        onClose={handleCloseEditImageModal}
        productId={selectedProductId}
      />
      <EditPriceModal
        isOpen={isOpenEditPriceModal}
        onClose={handleCloseEditPriceModal}
        productId={selectedProductId}
      />
      <EditInternalPriceModal
        isOpen={isOpenEditInternalPriceModal}
        onClose={handleCloseEditInternalPriceModal}
        productId={selectedInternalProductId}
      />
      <EditWeightModal
        isOpen={isOpenEditWeightModal}
        onClose={handleCloseEditWeightModal}
        productId={selectedProductId}
      />
    </>
  );
}
