import { ActionReducerMapBuilder, PayloadAction } from '@reduxjs/toolkit';
import {
  ProductListResponseType,
  ProductReduxStateType
} from 'src/types/product';
import { getProductsThunk } from '../thunks';
import { toast } from 'react-toastify';

export default function getProductsThunkHandler(
  builder: ActionReducerMapBuilder<ProductReduxStateType>
) {
  builder
    .addCase(getProductsThunk.pending, (state: ProductReduxStateType) => {
      state.isLoadingTable = true;
    })
    .addCase(
      getProductsThunk.fulfilled,
      (state, action: PayloadAction<ProductListResponseType>) => {
        state.products = action.payload.products;
        state.page = action.payload.page;
        state.totalPages = action.payload.totalPages;
        state.pageSize = action.payload.pageSize;
        state.isLoadingTable = false;
      }
    )
    .addCase(getProductsThunk.rejected, (state, action: PayloadAction<any>) => {
      state.products = [];
      state.page = 1;
      state.totalPages = 1;
      state.isLoadingTable = false;
      toast.error(action.payload.message);
    });
}
