import { ReactNode } from 'react';
import {
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableBody
} from '@mui/material';
import { styled } from '@mui/material/styles';
import Loading from '../Loading';

const StyledBodyCell = styled(TableCell)({});
const StyledBodyRow = styled(TableRow)({
  '&:hover': {
    backgroundColor: '#e0e0e0'
  }
});

const StyledHeadingCell = styled(TableCell)(({ theme }) => {
  return {
    textTransform: 'initial',
    fontWeight: '700 !important',
    color: 'black',
    background: '#cbcbcb'
  };
});

export type TableBodyRowType = { id: string | number; data: ReactNode[] };

export default function TGWTable({
  headings,
  rows,
  isLoading,
  height
}: {
  headings: ReactNode[];
  rows: TableBodyRowType[];
  isLoading: boolean;
  height: number | string;
}) {
  return (
    <TableContainer
      sx={{
        // height,
        background: 'white',
        minWidth: 0
      }}
    >
      <Table stickyHeader aria-label="sticky table">
        <TableHead>
          <TableRow>
            {headings?.map((heading, index: number) => (
              <StyledHeadingCell key={index}>{heading}</StyledHeadingCell>
            ))}
          </TableRow>
        </TableHead>
        {isLoading ? (
          <TableBody sx={{ position: 'relative', height: 200 }}>
            <TableRow>
              <TableCell colSpan={headings.length}>
                <Loading />
              </TableCell>
            </TableRow>
          </TableBody>
        ) : (
          <TableBody>
            {rows?.length > 0 ? (
              rows?.map((row: TableBodyRowType) => (
                <StyledBodyRow key={row.id}>
                  {row.data.map((cell: ReactNode, idx: number) => (
                    <StyledBodyCell key={idx}>{cell}</StyledBodyCell>
                  ))}
                </StyledBodyRow>
              ))
            ) : (
              <TableRow>
                <StyledBodyCell colSpan={headings.length} align="center">
                  Không có dữ liệu!
                </StyledBodyCell>
              </TableRow>
            )}
          </TableBody>
        )}
      </Table>
    </TableContainer>
  );
}
