import { useEffect, useState } from 'react';
import { Stack, Typography, TextField, Box, Button } from '@mui/material';
import { useSearchParams } from 'react-router-dom';
import paramsService from 'src/services/params';
import { ProductQueryType } from 'src/types/product';

export default function FilterAndSearch({
  query
}: {
  query: ProductQueryType;
}) {
  const setSearchParams = useSearchParams()[1];

  const [filterInfo, setFilterInfo] = useState<ProductQueryType>({});

  useEffect(() => {
    setFilterInfo(query);
  }, [query]);

  const handleSubmitForm = (e: React.SyntheticEvent) => {
    e.preventDefault();
    const newObject = filterInfo;
    delete newObject.page;
    delete newObject.pageSize;
    setSearchParams(paramsService.generateParamsObject(newObject));
  };

  const handleChangeField = (key: string, value: string) => {
    setFilterInfo({
      ...filterInfo,
      [key]: value
    });
  };

  const handleResetFilter = () => {
    setFilterInfo({});
  };

  return (
    <Stack
      pt={2}
      pl={2}
      sx={{ overflowY: 'auto', height: 'calc(100vh - 246px)', width: 200 }}
    >
      <form onSubmit={handleSubmitForm}>
        <Box mb={2}>
          <Typography fontWeight={600}>Tìm kiếm</Typography>
          <Typography fontWeight={500} mb={1}>
            (Tên sản phẩm)
          </Typography>
          <TextField
            value={filterInfo?.search || ''}
            name="search"
            type="text"
            onChange={(e) => {
              handleChangeField('search', e.target.value);
            }}
            fullWidth
          />
        </Box>
        <Stack mb={2} direction="row">
          <Button type="submit" variant="contained" sx={{ mr: 1 }}>
            Lọc
          </Button>
          <Button
            variant="outlined"
            onClick={handleResetFilter}
            color="secondary"
          >
            Đặt lại
          </Button>
        </Stack>
      </form>
    </Stack>
  );
}
