import { Stack, CircularProgress } from '@mui/material';

export default function Loading() {
  return (
    <Stack
      sx={{
        position: 'absolute',
        width: '100%',
        height: '100%',
        top: 0,
        left: 0,
        zIndex: 9999,
        backgroundColor: 'white'
      }}
      justifyContent="center"
      alignItems="center"
    >
      <CircularProgress size={64} disableShrink thickness={3} />
    </Stack>
  );
}
