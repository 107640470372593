import { LoadingButton } from '@mui/lab';
import * as yup from 'yup';
import {
  Autocomplete,
  Card,
  Grid,
  Stack,
  TextField,
  Typography,
  IconButton,
  Button,
  Modal,
  CardActions
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { Controller, useForm, useFieldArray } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  ReactNode,
  forwardRef,
  useImperativeHandle,
  useRef,
  useState
} from 'react';
import API from 'src/API';
import { useDebouncedCallback } from 'use-debounce';
import Table, { TableBodyRowType } from 'src/components/Table/index';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router';

export default () => {
  const confirmCreateRef = useRef<IModalRef>(null);
  const [recommendProducts, setRecommendProducts] = useState<
    IRecommendProduct[]
  >([]);

  const { control, handleSubmit } = useForm<ICreateComboForm>({
    mode: 'onSubmit',
    defaultValues: {
      citywhey_price: 0,
      compare_price: 0,
      retail_price: 0,
      sub_products: []
    },
    resolver: yupResolver(
      yup.object({
        citywhey_price: yup
          .number()
          .typeError('Vui lòng nhập giá trị hợp lệ')
          .positive('Vui lòng nhập giá trị hợp lệ')
          .required('Vui lòng nhập giá Citywhey'),
        compare_price: yup
          .number()
          .typeError('Vui lòng nhập giá trị hợp lệ')
          .positive('Vui lòng nhập giá trị hợp lệ')
          .required('Vui lòng nhập giá thị trường'),
        retail_price: yup
          .number()
          .typeError('Vui lòng nhập giá trị hợp lệ')
          .positive('Vui lòng nhập giá trị hợp lệ')
          .required('Vui lòng nhập giá bán lẻ'),
        sub_products: yup
          .array()
          .min(2, 'Vui lòng chọn ít nhất 2 sản phẩm')
          .of(
            yup.object({
              quantity: yup.number().positive().required()
            })
          )
          .required('Vui lòng chọn sản phẩm')
      })
    )
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'sub_products'
  });

  const debounced = useDebouncedCallback((value) => {
    getRecommendProducts(value);
  }, 500);

  const getRecommendProducts = async (search: string) => {
    try {
      const response = await API.product.getRecommendProducts(search);
      if (response.errorCode === 0) setRecommendProducts(response.data);
    } catch (error) {
      toast.error('Lỗi kết nối!');
    }
  };

  const handleAppendOption = (product: IRecommendProduct) => {
    let dupplicateProducts: IRecommendProduct[] = [];
    if (fields.length > 0) {
      dupplicateProducts = fields.filter(
        (item) => item.product_id === product.product_id
      );
    }
    if (dupplicateProducts.length > 0) {
      toast.error('Sản phẩm đã được thêm!');
    } else {
      append({
        name: product.name,
        product_id: product.product_id,
        quantity: 1
      });
    }
  };

  const headings: ReactNode[] = [
    <Typography key={0} textAlign="center"></Typography>,
    <Typography key={1} textAlign="center" fontWeight="bold">
      Số lượng
    </Typography>,
    <Typography key={2} fontWeight="bold">
      ID
    </Typography>,
    <Typography key={3} fontWeight="bold">
      Tên sản phẩm
    </Typography>
  ];

  const rows: TableBodyRowType[] = fields.length
    ? fields.map((field, index) => {
        const row: TableBodyRowType = { id: '', data: [] };
        row.id = field.id;
        row.data.push(
          <Stack direction="row" justifyContent="center" alignItems="center">
            <IconButton
              title="Xóa"
              id="deleteLineItem"
              sx={{ textAlign: 'center' }}
              onClick={(event: React.SyntheticEvent) => {
                remove(index);
              }}
            >
              <DeleteIcon color="error" />
            </IconButton>
          </Stack>
        );
        row.data.push(
          <Controller
            control={control}
            name={`sub_products.${index}.quantity`}
            render={({ field: { onChange, value } }) => (
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="center"
                gap={1}
              >
                <Button
                  size="small"
                  sx={{ fontSize: 30 }}
                  onClick={() => value > 1 && onChange(--value)}
                >
                  -
                </Button>
                <TextField
                  type="number"
                  value={value}
                  inputProps={{ style: { textAlign: 'center', width: 20 } }}
                />
                <Button
                  size="small"
                  sx={{ fontSize: 30 }}
                  onClick={() => onChange(++value)}
                >
                  +
                </Button>
              </Stack>
            )}
          />
        );
        row.data.push(
          <Typography textAlign="start">{field.product_id}</Typography>
        );
        row.data.push(<Typography textAlign="start">{field.name}</Typography>);
        return row;
      })
    : [];

  return (
    <>
      <form
        onSubmit={handleSubmit((data) =>
          confirmCreateRef.current?.openModal(data)
        )}
      >
        <Card sx={{ p: 4, mx: 2 }}>
          <Stack direction={'row'} justifyContent={'space-between'}>
            <Typography variant="h3">Tạo combo</Typography>
            <LoadingButton variant="contained" type="submit">
              Lưu
            </LoadingButton>
          </Stack>

          <Typography mt={3} mb={2} variant="h5">
            Giá combo:
          </Typography>
          <Grid container spacing={1}>
            <Grid item xs={12} md={4}>
              <Controller
                control={control}
                name="citywhey_price"
                render={({ formState: { errors }, field }) => (
                  <TextField
                    fullWidth
                    type="number"
                    label="Nhập giá Citywhey (VNĐ)"
                    {...field}
                    error={!!errors.citywhey_price}
                    helperText={errors.citywhey_price?.message}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <Controller
                control={control}
                name="compare_price"
                render={({ formState: { errors }, field }) => (
                  <TextField
                    fullWidth
                    type="number"
                    label="Nhập giá thị trường (VNĐ)"
                    {...field}
                    error={!!errors.compare_price}
                    helperText={errors.compare_price?.message}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <Controller
                control={control}
                name="retail_price"
                render={({ formState: { errors }, field }) => (
                  <TextField
                    fullWidth
                    type="number"
                    label="Nhập giá bán lẻ (VNĐ)"
                    {...field}
                    error={!!errors.retail_price}
                    helperText={errors.retail_price?.message}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12}>
              <Typography mt={3} mb={2} variant="h5">
                Danh sách sản phẩm trong combo:
              </Typography>
              <Controller
                control={control}
                name="sub_products"
                render={({ formState: { errors } }) => (
                  <Autocomplete
                    options={recommendProducts}
                    noOptionsText="Không có kết quả"
                    getOptionLabel={(product: IRecommendProduct) =>
                      product.name
                    }
                    onChange={(
                      _event: React.SyntheticEvent,
                      value: IRecommendProduct
                    ) => value && handleAppendOption(value)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Nhập tên sản phẩm"
                        onChange={(e) => debounced(e.target.value)}
                        error={!!errors.sub_products}
                        helperText={errors.sub_products?.message}
                      />
                    )}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12}>
              <Table
                headings={headings}
                rows={rows}
                isLoading={false}
                height="100%"
              />
            </Grid>
          </Grid>
        </Card>
      </form>

      <ConfirmCreateModal ref={confirmCreateRef} />
    </>
  );
};

interface IModalRef {
  openModal: (data: ICreateComboForm) => void;
}

const ConfirmCreateModal = forwardRef<IModalRef>((_, ref) => {
  const navigate = useNavigate();

  const [open, setOpen] = useState(false);

  const [data, setData] = useState<ICreateComboForm>();

  const toggle = () => setOpen((p) => !p);

  useImperativeHandle(ref, () => ({
    openModal(d) {
      setData(d);
      toggle();
    }
  }));

  const handleClickSubmit = async () => {
    try {
      const response = await API.product.createCombo({
        ...data,
        sub_products: data.sub_products.map((product) => ({
          id: product.product_id,
          quantity: product.quantity
        }))
      });
      switch (response.errorCode) {
        case 1:
          toast.error('Vui lòng kiểm tra lại thông tin');
          break;
        case 2:
          toast.error('Combo cần ít nhất 2 sản phẩm');
          break;
        case 0:
          toast.success('Tạo combo thành công');
          toggle();
          navigate('/products');
          break;
        default:
          break;
      }
    } catch (error) {
      toast.error('Có lỗi xảy ra');
    }
  };

  return (
    <Modal open={open} onClose={toggle}>
      <Card
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          minWidth: 400,
          pt: 3,
          pb: 2
        }}
      >
        <Typography variant="h4" mb={1} textAlign="center">
          Bạn có chắc muốn tạo combo này?
        </Typography>
        <CardActions sx={{ justifyContent: 'center' }}>
          <Button variant="contained" onClick={handleClickSubmit}>
            Tạo
          </Button>
          <Button variant="outlined" onClick={toggle}>
            Đóng
          </Button>
        </CardActions>
      </Card>
    </Modal>
  );
});
