import React from 'react';
import { NodeHtmlMarkdown } from 'node-html-markdown';
import {
  CardContent,
  Container,
  Grid,
  Card,
  Stack,
  Button
} from '@mui/material';
import { useParams } from 'react-router';
import { ProductType } from 'src/types/product';
import Typography from '@mui/material/Typography';
import MarkdownIt from 'markdown-it';
import MdEditor from 'react-markdown-editor-lite';
import 'react-markdown-editor-lite/lib/index.css';
import API from 'src/API';
import { toast } from 'react-toastify';
import CardActions from '@mui/material/CardActions';

const mdParser = new MarkdownIt();

const getMarkdownContent = (content: string) => {
  const markdown = NodeHtmlMarkdown.translate(
    content,
    {},
    undefined,
    undefined
  );
  return markdown;
};

export default function ProductDetailsPage() {
  const { product_id } = useParams();
  const [contentMarkdown, setContentMarkdown] = React.useState('');
  const [toggleReload, setReload] = React.useState<boolean>(false);
  const [contentHTML, setContentHTML] = React.useState('');
  const [product, setProduct] = React.useState<ProductType>({
    product_id: 241414,
    product_name: 'asfasfa',
    retail_price: 412040,
    in_stock: 412040,
    variant_count: 412040,
    weight_value: 412040,
    images: [],
    category: ''
  });

  React.useEffect(() => {
    if (product_id) {
      const getProductDetails = async () => {
        try {
          const response = await API.product.getProduct(product_id);
          if (response?.errorCode === 0) {
            setProduct(response.data);
          }
        } catch (error) {
          toast.error(error?.message || 'Lỗi hệ thống!');
        }
      };
      getProductDetails();
    }
  }, [product_id, toggleReload]);

  React.useEffect(() => {
    if (product?.description) {
      const markdown = getMarkdownContent(product.description);
      setContentMarkdown(markdown || '');
    } else {
      setContentMarkdown('');
    }
  }, [product?.description]);

  React.useEffect(() => {
    window.onbeforeunload = (event: BeforeUnloadEvent) => {
      const e = event || window.event;
      e.preventDefault();
      if (e) {
        e.returnValue = '';
      }
      return '';
    };
    return () => {
      window.onbeforeunload = (event: BeforeUnloadEvent) => {
        const e = event || window.event;
        e.preventDefault();
        if (e) {
          e.returnValue = '';
        }
        return '';
      };
    };
  }, []);

  const handleEditorChange = ({ html, text }) => {
    setContentHTML(html);
    setContentMarkdown(text);
  };

  const handleSubmitMarkdown = async () => {
    try {
      const response = await API.product.updateDescription(product_id, {
        contentHTML
      });
      switch (response.errorCode) {
        case 0:
          toast.success('Cập nhật thành công');
          setReload(!toggleReload);
          break;

        default:
          toast.error('Vui lòng kiểm tra lại');
          break;
      }
    } catch (error) {
      toast.error(error?.message || 'Lỗi hệ thống');
    }
  };

  const handleResetMarkdown = () => {
    setContentMarkdown(getMarkdownContent(product?.description || '') || '');
  };

  return (
    <>
      <Container maxWidth="lg">
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="stretch"
          spacing={4}
        >
          {product && (
            <Grid item xs={12} mb={2}>
              <Stack px={1.5} pb={1}>
                <Typography variant="h4">{product.product_name}</Typography>
                <Typography></Typography>
              </Stack>
              <Card>
                <CardContent>
                  <MdEditor
                    value={contentMarkdown}
                    style={{ height: '500px', marginBottom: '20px' }}
                    renderHTML={(text) => mdParser.render(text)}
                    onChange={handleEditorChange}
                  />
                </CardContent>
                <CardActions>
                  <Button variant="contained" onClick={handleSubmitMarkdown}>
                    Lưu
                  </Button>
                  <Button onClick={handleResetMarkdown}>Đặt lại</Button>
                </CardActions>
              </Card>
            </Grid>
          )}
        </Grid>
      </Container>
    </>
  );
}
