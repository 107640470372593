import { ActionReducerMapBuilder, PayloadAction } from '@reduxjs/toolkit';
import {
  ProductImageType,
  ProductType,
  ProductReduxStateType
} from 'src/types/product';
import { deleteImageThunk } from '../thunks';
import { toast } from 'react-toastify';

export default function deleteImageThunkHandler(
  builder: ActionReducerMapBuilder<ProductReduxStateType>
) {
  builder
    .addCase(deleteImageThunk.pending, (state: ProductReduxStateType) => {
      state.isSendingRequest = true;
    })
    .addCase(
      deleteImageThunk.fulfilled,
      (
        state: ProductReduxStateType,
        action: PayloadAction<{
          productId: ProductType['product_id'];
          deleteImg: ProductImageType;
        }>
      ) => {
        state.chosenProduct = {
          ...state.chosenProduct,
          images: state.chosenProduct.images.filter(
            (img: ProductImageType) =>
              img.full_path !== action.payload.deleteImg.full_path
          )
        };
        state.products = state.products.map((product: ProductType) =>
          product.product_id === action.payload.productId
            ? {
                ...product,
                images: product.images.filter(
                  (img: ProductImageType) =>
                    img.full_path !== action.payload.deleteImg.full_path
                )
              }
            : product
        );
        toast.success('Xóa ảnh thành công');
        state.isSendingRequest = false;
        state.isOpenConfirmDeleteModal = false;
      }
    )
    .addCase(
      deleteImageThunk.rejected,
      (state: ProductReduxStateType, action: PayloadAction<any>) => {
        state.isSendingRequest = false;
        toast.error(action.payload.message);
      }
    );
}
