import { ActionReducerMapBuilder, PayloadAction } from '@reduxjs/toolkit';
import {
  ProductReduxStateType,
  ProductType,
  ProductUpdateImagesThunkType
} from 'src/types/product';
import { updateImageThunk } from '../thunks';
import { toast } from 'react-toastify';

const updateImageThunkHandler = (
  builder: ActionReducerMapBuilder<ProductReduxStateType>
) => {
  builder
    .addCase(updateImageThunk.pending, (state: ProductReduxStateType) => {
      state.isSendingRequest = true;
    })
    .addCase(
      updateImageThunk.fulfilled,
      (
        state: ProductReduxStateType,
        action: PayloadAction<ProductUpdateImagesThunkType>
      ) => {
        state.products = state.products.map((product: ProductType) =>
          product.product_id === action.payload.product_id
            ? {
                ...product,
                images: action.payload.images
              }
            : product
        );
        toast.success('Upload ảnh thành công');
        state.isSendingRequest = false;
      }
    )
    .addCase(
      updateImageThunk.rejected,
      (state: ProductReduxStateType, action: PayloadAction<any>) => {
        toast.error(action.payload.message);
        state.isSendingRequest = false;
      }
    );
};

export default updateImageThunkHandler;
