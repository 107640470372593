import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import localStorage from 'redux-persist/lib/storage';
import authSlice from './auth-slice';
import appUISlice from './app-ui-slice';
import productSlice from './product';
import tagSlice from './tag-slice';

const authPersistConfig = {
  key: 'auth',
  storage: localStorage
};

const rootReducer = combineReducers({
  auth: persistReducer(authPersistConfig, authSlice.reducer),
  product: productSlice.reducer,
  tag: tagSlice.reducer,
  appUI: appUISlice.reducer
});

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false
    })
});

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;

export default store;
