import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../store';
import useActions from './useActions';
import useFetch from './useFetch';

const useCustomHooks = {
  useActions,
  useFetch
};
export const useAppDispatch: () => AppDispatch = useDispatch;

export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export default useCustomHooks;
