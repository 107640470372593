import useFetch, { IResponse } from 'src/hooks/useFetch';
import {
  ProductEditPriceStateType,
  ProductEditWeightStateType,
  ProductQueryType,
  ProductType,
  ProductUpdateDescriptionType,
  ProductListResponseType,
  InternalInfoType,
  InternalDetailProductType,
  SaleReportQueryType,
  SaleReportResponseType
} from 'src/types/product';

const getProducts = ({
  page,
  pageSize,
  search
}: ProductQueryType): Promise<IResponse<ProductListResponseType>> => {
  let url = '/api/products';
  let queryArr = [];
  if (page) queryArr.push(`page=${page}`);
  if (pageSize) queryArr.push(`pageSize=${pageSize}`);
  if (search) queryArr.push(`search=${search}`);
  if (queryArr.length > 0) url += '?' + queryArr.join('&');
  return useFetch.local.get(url);
};

const getProduct = (
  productId: number | string
): Promise<IResponse<ProductType>> => {
  return useFetch.local.get(`/api/products/${productId}`);
};

const updatePrice = (
  product_id: number | string,
  body: ProductEditPriceStateType
): Promise<IResponse> => {
  return useFetch.local.post(`/api/products/${product_id}/price`, body);
};

const updateWeight = (
  product_id: number | string,
  body: ProductEditWeightStateType
): Promise<IResponse> => {
  return useFetch.local.post(`/api/products/${product_id}/weight`, body);
};

const deleteImage = (
  product_id: number | string,
  img_id: number | string
): Promise<IResponse> => {
  return useFetch.local.delete(`/api/products/${product_id}/images/${img_id}`);
};

const uploadImages = (
  product_id: number | string,
  body: string[]
): Promise<IResponse> => {
  return useFetch.local.post(`/api/products/${product_id}/images`, body);
};

const updateDescription = (
  product_id: number | string,
  body: ProductUpdateDescriptionType
): Promise<IResponse> => {
  return useFetch.local.post(`/api/products/${product_id}/description`, body);
};

const getRecommendProducts = (
  search: string | undefined
): Promise<IResponse<IRecommendProduct[]>> =>
  useFetch.local.get(`/api/products/search?search=${search || ''}`);

const createCombo = (data): Promise<IResponse> =>
  useFetch.local.post('/api/products/combo', data);

const getInternalProductDetail = (
  id: number
): Promise<IResponse<InternalDetailProductType>> =>
  useFetch.local.get(`/api/products/internal-detail/${id}`);

const updateInternalPrice = (
  id: number,
  data: Pick<InternalInfoType, 'shopee_retail_price' | 'tiktok_retail_price'>
): Promise<
  IResponse<{ updatedProduct: InternalInfoType & { product_id: number } }>
> =>
  useFetch.local.patch(`/api/products/${id}/internal-price`, {
    shopee_price: data.shopee_retail_price,
    tiktok_price: data.tiktok_retail_price
  });

const getSaleReport = ({
  page,
  month,
  pageSize
}: SaleReportQueryType): Promise<IResponse<SaleReportResponseType>> => {
  let url = '/api/products/report';
  let queryArr = [];
  if (page) queryArr.push(`page=${page}`);
  if (pageSize) queryArr.push(`pageSize=${pageSize}`);
  if (month) queryArr.push(`month=${month}`);
  if (queryArr.length > 0) url += '?' + queryArr.join('&');
  return useFetch.local.get(url);
};

export default {
  getProducts,
  getProduct,
  updatePrice,
  updateWeight,
  deleteImage,
  uploadImages,
  updateDescription,
  getRecommendProducts,
  createCombo,
  getInternalProductDetail,
  updateInternalPrice,
  getSaleReport
};
