import { useState } from 'react';
import {
  Button,
  CssBaseline,
  TextField,
  Box,
  Typography,
  Container,
  IconButton,
  Tooltip,
  Stack
} from '@mui/material';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useAppDispatch, useAppSelector } from 'src/hooks';
import { useForm, SubmitHandler } from 'react-hook-form';
import { UserAccountType } from 'src/types/user';
import useActions from 'src/hooks/useActions';
import { RootState } from 'src/store';
import { UseFormType } from 'src/types/form';
import VisibilityRoundedIcon from '@mui/icons-material/VisibilityRounded';
import VisibilityOffRoundedIcon from '@mui/icons-material/VisibilityOffRounded';

const theme = createTheme();

const userLoginSchema: yup.SchemaOf<UserAccountType> = yup.object().shape({
  username: yup.string().required('Tên đăng nhập không được để trống!'),
  password: yup.string().required('Mật khẩu không được để trống!')
});

const Login = () => {
  const [passInputType, setPassInputType] = useState<string>('password');

  const dispatch = useAppDispatch();

  const isLogginIn = useAppSelector(
    (state: RootState) => state.appUI.isLoggingin
  );

  const loginForm: UseFormType = useForm<UserAccountType>({
    mode: 'onTouched',
    defaultValues: { username: '', password: '' },
    resolver: yupResolver(userLoginSchema)
  });

  const onSubmit: SubmitHandler<UserAccountType> = (data: UserAccountType) => {
    dispatch(useActions.auth.login(data));
  };

  const handleShowPassword = () => {
    if (passInputType === 'password') {
      setPassInputType('text');
    } else {
      setPassInputType('password');
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          backgroundColor: '#0b75e742',
          width: '100vw',
          height: '100vh',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <Container
          component="main"
          maxWidth="xs"
          sx={{
            boxShadow: '0 0 1rem 0 rgba(0, 0, 0, .2)',
            borderRadius: '15px',
            backgroundColor: '#fff',
            backdropFilter: 'blur(15px)',
            mx: 4
          }}
        >
          <CssBaseline />
          <Box
            sx={{
              mt: 3,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center'
            }}
          >
            <Typography variant="h4" component="h1" sx={{ color: '#0b76e7' }}>
              Marketing Helper
            </Typography>

            <form onSubmit={loginForm.handleSubmit(onSubmit)}>
              <TextField
                margin="normal"
                fullWidth
                label="Username"
                name="username"
                // autoComplete="username"
                disabled={isLogginIn}
                autoFocus
                {...loginForm.register('username')}
                error={!!loginForm.formState.errors?.username}
                helperText={
                  loginForm.formState.errors?.username
                    ? loginForm.formState.errors.username.message
                    : null
                }
              />

              <TextField
                margin="normal"
                fullWidth
                name="password"
                label="Password"
                type={passInputType}
                disabled={isLogginIn}
                autoComplete="current-password"
                InputProps={{
                  endAdornment: (
                    <Box onClick={handleShowPassword}>
                      {passInputType === 'password' ? (
                        <Tooltip title="Show">
                          <IconButton>
                            <VisibilityOffRoundedIcon />
                          </IconButton>
                        </Tooltip>
                      ) : (
                        <Tooltip title="Hide">
                          <IconButton>
                            <VisibilityRoundedIcon />
                          </IconButton>
                        </Tooltip>
                      )}
                    </Box>
                  )
                }}
                {...loginForm.register('password')}
                error={!!loginForm.formState.errors?.password}
                helperText={
                  loginForm.formState.errors?.password
                    ? loginForm.formState.errors.password.message
                    : null
                }
              />

              <Button
                type="submit"
                fullWidth
                variant="contained"
                disabled={isLogginIn}
                sx={{ mt: 3, mb: 5 }}
              >
                Đăng nhập
              </Button>
            </form>
          </Box>
        </Container>
      </Box>
    </ThemeProvider>
  );
};

export default Login;
