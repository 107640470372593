import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import API from 'src/API';
import { toast } from 'react-toastify';
import {
  TagListResponseType,
  TagListStateType,
  TagQueryType
} from 'src/types/tag';

const getTagList = createAsyncThunk(
  'tag/getTagList',
  async (queryInfo: TagQueryType, { rejectWithValue }) => {
    try {
      const response = await API.tag.getTagList(queryInfo);
      return response;
    } catch (error) {
      toast.error(error ? error.message : 'Lỗi hệ thống!');
      return rejectWithValue(error);
    }
  }
);

const initialState: TagListStateType = {
  tagList: [],
  totalPages: 1,
  isLoadingTable: false
};

const slice = createSlice({
  name: 'tag',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getTagList.pending, (state) => {
        state.isLoadingTable = true;
      })
      .addCase(
        getTagList.fulfilled,
        (state, action: PayloadAction<TagListResponseType>) => {
          state.isLoadingTable = false;
          state.tagList = action.payload.data;
          state.totalPages = action.payload.totalPages;
        }
      )
      .addCase(getTagList.rejected, (state) => {
        state.isLoadingTable = false;
        state.tagList = [];
        state.totalPages = 1;
      });
  }
});

export default { ...slice, actions: { ...slice.actions, getTagList } };
