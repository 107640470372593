import { TextField } from '@mui/material';
import moment from 'moment';

function TimePicker({
  onChange,
  value
}: {
  value: string;
  onChange(value: string): void;
}) {
  // const [date, setDate] = useState<string>();

  // const [searchParams] = useSearchParams();

  // useEffect(() => {
  //   const dateFromQuery = searchParams.get('month');
  //   setDate(dateFromQuery || moment(new Date()).format('YYYY-MM'));
  // }, [searchParams]);

  return (
    <TextField
      type="month"
      label="Chọn tháng"
      value={value || moment(new Date()).format('YYYY-MM')}
      onChange={(e) => {
        onChange(e.target.value);
      }}
    />
  );
}

export default TimePicker;
