import {
  TagCreateBodyType,
  TagDeleteType,
  TagEditBodyType,
  TagLineItemType,
  TagListResponseType
} from './../types/tag';
import useFetch from 'src/hooks/useFetch';
import { TagQueryType, TagType } from 'src/types/tag';

const getTagList = ({
  page,
  pageSize,
  startDate,
  endDate,
  search,
  isHotTrend
}: TagQueryType): Promise<TagListResponseType> => {
  let url = '/api/tags';
  let queryArr = [];
  if (page) queryArr.push(`page=${page}`);
  if (pageSize) queryArr.push(`pageSize=${pageSize}`);
  if (startDate) queryArr.push(`createAt=${startDate}`);
  if (endDate) queryArr.push(`createAt=${endDate}`);
  if (search) queryArr.push(`search=${search}`);
  if (isHotTrend) queryArr.push(`isHotTrend=${isHotTrend}`);
  if (queryArr.length > 0) url += '?' + queryArr.join('&');
  return useFetch.local.get(url);
};

const getTagDetails = (tagId: number): Promise<TagType> => {
  return useFetch.local.get(`/api/tags/${tagId}`);
};

const deleteTag = (tagId: number): Promise<TagDeleteType> => {
  return useFetch.local.delete(`/api/tags/${tagId}`);
};

const updateTag = (tagId: number, body: TagEditBodyType): Promise<any> => {
  return useFetch.local.put(`/api/tags/${tagId}`, body);
};

const createTag = (
  body: TagCreateBodyType
): Promise<Omit<TagType, 'lineItems'>> => {
  return useFetch.local.post('/api/tags', body);
};

const getTagLineItems = (search: string): Promise<TagLineItemType[]> => {
  return useFetch.local.get(`/api/products/recommend?search=${search}`);
};

export default {
  getTagList,
  getTagDetails,
  deleteTag,
  updateTag,
  createTag,
  getTagLineItems
};
